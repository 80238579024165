import '../styles/Podcasts.scss'
import App from '../components/App'
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PodcastInfo from '../atoms/Podcasts/Info'
import PodcastPartners from '../atoms/Podcasts/Partners'
import PodcastPlatforms from '../atoms/Podcasts/Platforms'
import React from 'react'
import SEO, { getSeoMedia } from '../atoms/SEO'
import styled from 'styled-components'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { graphql } from 'gatsby'

const PodcastSecionEnv = ({ children }) => (
  <div className="container">
    <div className="row">
      <div className="designer-col col-12 podcasts-col">{children}</div>
    </div>
  </div>
)

const Main = styled.main`
  min-height: unset !important;
  max-height: fit-content;

  ${BreadCrumbs} {
    position: absolute;
    z-index: 2;
  }
`

const Podcast = ({ data }) => {
  const sources = [
    data.backgroundImageXXS.childImageSharp.fluid,
    {
      ...data.backgroundImageXS.childImageSharp.fluid,
      media: `(min-width: 0px)`
    },
    {
      ...data.backgroundImage.childImageSharp.fluid,
      media: `(min-width: 414px)`
    }
  ]

  return (
    <App>
      <SEO
        children={<BreadcrumbSEO />}
        description="Подкаст «Спасибо, я в порядке» о психотерапии и психическом здоровье. Вместе с психологами разбираемся, как подобрать специалиста, что происходит во время психотерапии и как влияют на нас различные проблемы."
        images={getSeoMedia([
          { pathname: '/img/og/podcasts.png', alt: 'podcast' }
        ])}
        title="Подкасты по психологии и психотерапии | YouTalk"
      />
      <Header />
      <Main>
        <BreadCrumbs />
        <BackgroundImage
          Tag="section"
          backgroundColor="transparent"
          className="podcasts-top__backgound"
          fluid={sources}
        />
        <section className="podcasts-top">
          <div className="podcasts-top-decor_top" />
          <PodcastSecionEnv>
            <div className="podcasts-top-content">
              <div className="podcasts-top-content-col-8">
                <PodcastInfo />
              </div>
              <div className="podcasts-top-content-col-4">
                <PodcastPlatforms />
              </div>
            </div>
          </PodcastSecionEnv>
          <div className="podcasts-top-decor_bottom" />
        </section>
        <section className="podcasts-middle">
          <PodcastSecionEnv>
            <PodcastPartners />
          </PodcastSecionEnv>
        </section>
      </Main>
      <Footer />
    </App>
  )
}

export const query = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "img/podcasts/podcasts.png" }) {
      childImageSharp {
        fluid(maxWidth: 4160) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundImageXS: file(
      relativePath: { eq: "img/podcasts/podcasts-xs.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundImageXXS: file(
      relativePath: { eq: "img/podcasts/podcasts-xxs.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Podcast
