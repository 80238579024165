import './index.scss'
import React from 'react'

const PodcastPartners = () => (
  <div className="partners">
    <div className="partners-title h5">Информационные партнеры</div>
    <div className="partners-items">
      <span className="partners-items__partner" />
      <span className="partners-items__partner" />
    </div>
  </div>
)

export default PodcastPartners
