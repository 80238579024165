import './index.scss'
import React from 'react'

const PodcastInfo = () => (
  <>
    <h1 className="podcasts-top-content__title">
      <div className="podcasts-top-content__title-prev h3">
        ПОДКАСТ по психологии
      </div>
      «Спасибо,
      <br /> я в порядке»
    </h1>
    <p className="podcasts-top-content__description">
      Все, что вы хотели знать о психотерапии, но боялись спросить. Подкаст от
      онлайн-сервиса психологической помощи YouTalk.
    </p>
    <p className="podcasts-top-content__description">
      Четыре сезона дискуссий, интервью, честных вопросов и ответов о том, что
      такое психотерапия, как она устроена, как правильно подойти к вопросу
      выбору психолога, и, главное, как психотерапия может качественно изменить
      вашу жизнь.
    </p>
    <p className="podcasts-top-content__description">
      Вместе с гостями и ведущими подкаста&nbsp;—&nbsp;известными людьми и
      опытными психологами&nbsp;—&nbsp;разбираемся в проблемах, с которыми
      сталкивается если не каждый, то точно большинство из нас.
      <br />
      <br />
      Производство студии подкастов{` `}
      <a href="https://brainstorm.fm/">Brainstorm.fm</a>
    </p>
  </>
)

export default PodcastInfo
