import './index.scss'
import React from 'react'

const platfroms = [
  {
    link: 'https://music.yandex.ru/album/13260902/track/75479352'
  },
  {
    link: 'https://open.spotify.com/show/7CUCUU6vUaUL998SxZmi0C?si=wiGQmMCWQpuVdUqDujcF3w'
  },
  {
    link: 'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80NTNhNDcyMC9wb2RjYXN0L3Jzcw?sa=X&ved=0CAMQ4aUDahcKEwiYxPuG5sDuAhUAAAAAHQAAAAAQAg'
  },
  {
    link: 'https://podcasts.apple.com/ru/podcast/%D1%81%D0%BF%D0%B0%D1%81%D0%B8%D0%B1%D0%BE-%D1%8F-%D0%B2-%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B5/id1546119171'
  }
]

const PodcastPlatforms = () => (
  <div className="podcasts-top-content__platforms">
    <div className="podcasts-top-content__platforms-title h5">
      Слушайте нас
      <br /> на платформах:
    </div>
    <div className="podcasts-top-content__platforms-links">
      {platfroms.map(({ link }) => (
        <a key={link} href={link} rel="noreferrer" target="_blank" />
      ))}
    </div>
  </div>
)

export default PodcastPlatforms
